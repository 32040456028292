import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function Drawer({ callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {callouts.map((callout, i) => {
          const customData = JSON.parse(callout.customData);

          return (
            <Grid as="article" columns="repeat(2, minmax(0,1fr))" verticalAlign="center" key={i}>
              <Column style={{ order: i % 2 }}>
                {!!callout.heading && <h3>{callout.heading}</h3>}
                {callout.content && <MDXRenderer>{callout.contentNode.childMdx.body}</MDXRenderer>}
                {callout.ctas?.map((cta, i) => {
                  const ctaFlags = i > 0 ? { secondary: true } : {};
                  return (
                    <Button to={cta.url} key={i} {...ctaFlags}>
                      {cta.text}
                    </Button>
                  );
                })}
              </Column>
              <Column>
                {callout.media ? <FluidImg data={callout.media} /> : <div>image should be there</div>}
              </Column>
            </Grid>
          );
        })}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
